import React, { createContext, useContext, useMemo, useState } from "react";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import type { Message, Thread } from "../../../@types/sd/assistant";
import {
  useAddMessageToThread,
  useCreateAssistantThread,
} from "../../../api/AssistantApi";
import useSSE from "../../../hooks/UseSSE";

type ChatState = "idle" | "creating_thread" | "waiting_response";

export type ChatContextType = {
  addMessageToThread: (message: string) => void;
  state: ChatState;
  messages: Message[];
};

const ChatContext = createContext<ChatContextType>({} as ChatContextType);

const toaster = OverlayToaster.create();

export type ChatContextProps = {
  children: React.ReactNode;
};

export const ChatContextProvider = ({ children }: ChatContextProps) => {
  const [thread, setThread] = useState<Thread | null>(null);
  const [chatState, setChatState] = useState<ChatState>("idle");
  const [messages, setMessage] = useState<Message[]>([]);

  const [pendingMessage, setPendingMessage] = useState<string | null>(null);

  const createMessage = useAddMessageToThread(
    () => {},
    (error) => {
      toaster.show({
        message: error.response?.data?.error || "Failed to send message",
        intent: Intent.DANGER,
      });
    }
  );
  const createThread = useCreateAssistantThread(
    (newThread) => {
      setThread(newThread);
      setChatState("idle");
      toaster.show({
        message:
          "Chat thread created successfully. Send a message to start chatting!",
        intent: Intent.SUCCESS,
      });

      if (pendingMessage) {
        createMessage.mutate({
          url: `/api/v2/orgs/:orgName/assistants/sally/threads/${newThread.id}/messages`,
          data: {
            content: pendingMessage,
          },
        });
        setPendingMessage(null);
      }
    },
    (error) => {
      toaster.show({
        message: error.response?.data?.error || "Failed to create chat thread",
        intent: Intent.DANGER,
      });
      setChatState("idle");
    }
  );

  const streamUrl = thread
    ? `/api/v2/orgs/:orgName/assistants/sally/threads/${thread.id}/stream`
    : undefined;

  useSSE<{ action: "add"; add: Message }>(
    streamUrl,
    (streamMessage) => {
      console.log(streamMessage);
      if (streamMessage.add.role === "assistant") {
        setMessage((prev) => [...prev, streamMessage.add]);
        setChatState("idle");
      }
    },
    (error) => {
      toaster.show({
        message: "Failed to receive assistant's response",
        intent: Intent.DANGER,
      });
      console.log(error);
      setChatState("idle");
    },
    (error) => {
      toaster.show({
        message: "Connection to assistant lost",
        intent: Intent.DANGER,
      });
      console.log(error);
      setChatState("idle");
    }
  );

  /**
   * addMessageToThread:
   *  - Always show the user's message immediately
   *  - If we're in the process of creating the thread, just queue/overwrite the pending message
   *  - If no thread has been created yet, create one and store the message
   *  - If we have a thread and are idle, send immediately
   */
  const addMessageToThread = (message: string) => {
    // Don't allow empty messages
    if (!message.trim()) {
      toaster.show({
        message: "Message cannot be empty",
        intent: Intent.WARNING,
      });
      return;
    }

    // Always show the user's message immediately
    setMessage((prev) => [
      ...prev,
      {
        role: "user",
        id: "custom_ui",
        createdAt: new Date().toISOString(),
        content: message,
      },
    ]);

    // If we're in the process of creating the thread, just queue/overwrite the pending message
    if (chatState === "creating_thread") {
      setPendingMessage(message);
      return;
    }

    // If no thread has been created yet, create one and store the message
    if (!thread) {
      setPendingMessage(message);
      setChatState("creating_thread");
      createThread.mutate({
        url: `/api/v2/orgs/:orgName/assistants/sally/threads`,
      });
      return;
    }

    // Otherwise, if we do have a thread and we're idle, send right away
    if (chatState === "idle") {
      createMessage.mutate({
        url: `/api/v2/orgs/:orgName/assistants/sally/threads/${thread.id}/messages`,
        data: {
          content: message,
        },
      });
      setChatState("waiting_response");
    } else {
      toaster.show({
        message: "Please wait for the assistant to respond",
        intent: Intent.WARNING,
      });
    }
  };

  // Build context value
  const value: ChatContextType = useMemo(
    () => ({
      addMessageToThread,
      messages: messages,
      state: chatState,
    }),
    [chatState, messages]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatContextProvider");
  }
  return context;
};
