import { useEffect, useRef } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import _ from "lodash";
import { useAuth } from "../contexts/AuthContext";

export const EOF = "EOF";

const useSSE = <T>(
  url: string | undefined,
  onMessage: (data: T) => void,
  onSignal: (signal: string) => void,
  onError: (error: string) => void,
  debounce?: boolean,
): void => {
  const { AuthToken, org } = useAuth();

  const lastDebounceRef = useRef<_.DebouncedFunc<() => void>>(null);

  useEffect(() => {
    if (!url || !onMessage) return;
    const baseURL = process.env.VITE_API_BASE_URL;
    const generatedURL = `${baseURL}${url}`.replace(
      ":orgName",
      org?.name || ""
    );
    const eventSource = new EventSourcePolyfill(generatedURL, {
      headers: { Authorization: `Bearer ${AuthToken}` },
      heartbeatTimeout: 60 * 1000,
    });


    eventSource.onmessage = (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data) as T;

        if (!debounce) {
          onMessage(message);
          return;
        }

        lastDebounceRef.current = _.debounce(() => onMessage(message), 200);
        lastDebounceRef.current();
      } catch (error) {
        console.error("Failed to parse stream data:", error);
      }
    };

    eventSource.addEventListener("signal", (event: MessageEvent) => {
      if (event.data === EOF) {
        eventSource.close();
        return;
      }
      onSignal(event.data);
    });

    eventSource.addEventListener("error", (event: MessageEvent) => {
      onError(event.data as string);
    });

    eventSource.onerror = (error: Event) => {
      console.error("SSE error:", JSON.stringify(error));
      eventSource.close();
    };

    return () => {
      eventSource.close();

      if (lastDebounceRef.current) {
        lastDebounceRef.current?.cancel();
      }
    };
  }, [url]);
};

export default useSSE;
