import { useQuery } from "react-query";
import axios from "axios";

import {
  Card,
  ControlGroup,
  HTMLSelect,
  InputGroup,
  Checkbox,
} from "@blueprintjs/core";
import produce from "immer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContext";
import SdButton from "../../components/theming/SdButton";
import { SdHeading1, SdHeading5 } from "../../components/theming/SdHeading";
import type { CreateRouteGroupRequest } from "../../api/RouteGroupsApi";
import { useSaveRouteGroupApi } from "../../api/RouteGroupsApi";
import { useClustersApi } from "../../api/ClustersApi";
import Loading from "../../components/Loading/Loading";
import HistoryLink from "../../components/HistoryLink";
import type { RouteGroup } from "../../@types/sd/routegroup";
import SdTheme from "../../styles/theme";
import Spacer from "../../components/Util/Util";
import EndpointSelect, { ClusterEndpoint } from "./EndpointSelect";
import { TTL_POLICIES } from "./Constants";
import { useTTL } from "../../hooks/UseTTL";
import TTLSelector from "../../components/TTLSelector/TTLSelector";

export function useClusterEndpoints(clusterName: string): ClusterEndpoint[] {
  const { AuthToken, org } = useAuth();
  const url = `/api/v2/orgs/${org!.name}/clusters/${clusterName}/services`;

  const { data: clusterEndpointsData, isSuccess } = useQuery(
    ["clusters", clusterName, "endpoints"],
    async () => {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
      });
      return data.map(
        ({
          name,
          namespace,
          port,
        }: {
          name: string;
          namespace: string;
          port: number;
        }) => new ClusterEndpoint({ name, namespace, port })
      );
    },
    {
      enabled: !!clusterName,
    }
  );
  return isSuccess ? clusterEndpointsData : [];
}

type Props = {
  setDrawerOpen: (open: boolean) => void;
  routeGroup?: RouteGroup;
};

type Label = {
  id: number;
  name: string;
  value: string;
};

type Endpoint = {
  id: number;
  name: string;
  url: string;
};

const ValidationError = styled.div`
  margin-bottom: 0.5rem;
  color: ${SdTheme.Status.bad};
`;

type CreateLabelProps = {
  children?: React.ReactNode;
  minWidth?: number | string;
};

const CreateLabel: React.FunctionComponent<CreateLabelProps> = ({
  children,
  minWidth = 85,
}) => (
  <span
    style={{
      minWidth: minWidth,
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    }}
  >
    {children}
  </span>
);

const CreateRouteGroup: React.FunctionComponent<Props> = ({
  setDrawerOpen,
  routeGroup,
}) => {
  // All items in the labels / endpoints lists should have a unique ID for React to use
  // as a key. The name field is not adequate for this because the user can add multiple
  // entries that will have an empty name field, causing duplicate keys to exist. So
  // we'll create our own always-incremented IDs each time someone adds an entry to
  // either list, which will reset whenever this component is unmounted.
  const nextId = useRef(1);
  const [name, setName] = useState(routeGroup ? routeGroup.name : "");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState(
    routeGroup?.spec?.description ?? ""
  );
  const [clusterName, setClusterName] = useState(
    routeGroup ? routeGroup.spec.cluster : ""
  );

  const [matchType, setMatchType] = useState(() => {
    if (!routeGroup) {
      return "all";
    }

    if (routeGroup.spec.match && routeGroup.spec.match.all) {
      return "all";
    }
    return "any";
  });
  const [labels, setLabels] = useState<Label[]>(() => {
    if (!routeGroup || !routeGroup.spec.match) {
      return [{ id: nextId.current++, name: "", value: "" }];
    }

    let match = routeGroup.spec.match.all;
    if (!match) {
      match = routeGroup.spec.match.any;
    }
    return match!.map((item) => ({
      id: nextId.current++,
      name: item.label!.key,
      value: item.label!.value,
    }));
  });

  const {
    ttl,
    onChange: onTTLChange,
    policies,
  } = useTTL({
    offsetFrom:
      routeGroup?.spec.ttl?.offsetFrom ??
      TTL_POLICIES.NO_MATCHED_SANDBOXES.value,
    duration: routeGroup?.spec.ttl?.duration,
    policies: TTL_POLICIES,
  });
  const [labelErrors, setLabelErrors] = useState(new Map<number, string>());

  const [endpoints, setEndpoints] = useState<Endpoint[]>(() =>
    routeGroup
      ? routeGroup.spec.endpoints.map((endpoint) => ({
          name: endpoint.name,
          url: endpoint.target,
          id: nextId.current++,
        }))
      : []
  );
  const [endpointErrors, setEndpointErrors] = useState(
    new Map<number, string>()
  );

  const [isSaveDisabled, setSaveDisabled] = useState(false);
  const [apiError, setApiError] = useState("");

  const routeGroupSaveApi = useSaveRouteGroupApi();

  const clustersApi = useClustersApi();
  const clusterNames = useMemo(() => {
    if (!clustersApi.clusters) {
      return null;
    }

    return clustersApi.clusters.map((cluster) => cluster.name);
  }, [clustersApi.clusters]);

  const onRouteGroupSave = useCallback(() => {
    let hasError = false;
    // Validate RouteGroup attributes.
    if (name.trim().length === 0) {
      hasError = true;
      setNameError("Route Group must have a name.");
    }

    // Validate labels.
    const labelValidationErrors = new Map<number, string>();
    for (let i = 0; i < labels.length; i++) {
      const { name: labelName, value } = labels[i];
      if (labelName.trim().length === 0) {
        hasError = true;
        labelValidationErrors.set(i, "All labels require a name.");
        continue;
      }
      if (value.trim().length === 0) {
        hasError = true;
        labelValidationErrors.set(i, "All labels require a value.");
      }
    }
    setLabelErrors(labelValidationErrors);

    // Validate endpoints.
    const knownEndpoints = new Set<string>();
    const endpointValidationErrors = new Map<number, string>();
    for (let i = 0; i < endpoints.length; i++) {
      const { name: endpointName, url } = endpoints[i];
      if (endpointName.trim().length === 0) {
        hasError = true;
        endpointValidationErrors.set(i, "All endpoints require a name.");
        continue;
      }
      if (knownEndpoints.has(endpointName)) {
        hasError = true;
        endpointValidationErrors.set(i, "Endpoint name must be unique.");
        continue;
      } else {
        knownEndpoints.add(endpointName);
      }
      if (url.trim().length === 0) {
        hasError = true;
        endpointValidationErrors.set(i, "All endpoints require a URL.");
        continue;
      }
    }
    setEndpointErrors(endpointValidationErrors);

    // Make API call if no validation errors were encountered.
    if (!hasError) {
      const request: CreateRouteGroupRequest = {
        name,
        spec: {
          description,
          cluster: clusterName,
          endpoints: endpoints.map((endpoint) => ({
            name: endpoint.name,
            target: endpoint.url,
          })),
        },
      };

      switch (matchType) {
        case "all":
          request.spec.match = {
            all: labels.map((label) => ({
              label: {
                key: label.name,
                value: label.value,
              },
            })),
          };
          break;
        case "any":
          request.spec.match = {
            any: labels.map((label) => ({
              label: {
                key: label.name,
                value: label.value,
              },
            })),
          };
          break;
        default:
          // shouldn't happen
          break;
      }

      request.spec.ttl = undefined;
      if (ttl.enabled) {
        request.spec.ttl = {
          duration: `${ttl.duration}${ttl.durationType}`,
          offsetFrom: ttl.offsetFrom,
        };
      }

      routeGroupSaveApi.mutate({
        url: `/api/v2/orgs/:orgName/routegroups/${name}`,
        data: request,
      });
      setSaveDisabled(true);
    }
  }, [name, description, clusterName, matchType, labels, endpoints, ttl]);

  // Set the default selected cluster once it finishes loading.
  useEffect(() => {
    if (
      !routeGroup &&
      clustersApi.clusters &&
      clustersApi.clusters.length > 0
    ) {
      setClusterName(clustersApi.clusters[0].name);
    }
  }, [clustersApi.clusters]);
  // Set error state if the RouteGroup create API call fails.
  useEffect(() => {
    if (routeGroupSaveApi.error) {
      setApiError(routeGroupSaveApi.error.response.data.error);
      setSaveDisabled(false);
    }
  }, [routeGroupSaveApi.error]);
  // Close the RouteGroup creation UI once the API call succeeds.
  useEffect(() => {
    if (routeGroupSaveApi.isSuccess) {
      setDrawerOpen(false);
    }
  }, [routeGroupSaveApi.isSuccess]);

  const clusterEndpoints = useClusterEndpoints(clusterName);

  if (!clustersApi.clusters) {
    return <Loading />;
  }
  if (clustersApi.clusters.length === 0) {
    return (
      <div>
        Please <HistoryLink url="/clusters">connect a cluster</HistoryLink>{" "}
        before trying to create a Route Group.
      </div>
    );
  }

  return (
    <div className="mx-4 z-40 h-full overflow-auto">
      <span>
        <SdHeading1 small lightBackground>
          {routeGroup ? "Edit" : "Create"} Route Group
        </SdHeading1>
      </span>
      <Spacer />
      <Spacer />

      <ControlGroup className="mb-2">
        <CreateLabel>Name:</CreateLabel>
        <InputGroup
          // If we're editing an existing RouteGroup, we can't change the value that acts
          // as the ID of the RouteGroup when using the API.
          disabled={!!routeGroup}
          placeholder="name"
          fill
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </ControlGroup>
      {nameError && (
        <ValidationError className="mx-4">{nameError}</ValidationError>
      )}

      <ControlGroup className="mb-2">
        <CreateLabel>Description:</CreateLabel>
        <InputGroup
          disabled={!!routeGroup}
          placeholder="description"
          fill
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />
      </ControlGroup>

      <ControlGroup className="mb-2">
        <CreateLabel>Cluster:</CreateLabel>
        <HTMLSelect
          disabled={!!routeGroup}
          value={clusterName}
          onChange={(item) => {
            setClusterName(item.target.value);
          }}
          options={clusterNames || []}
        />
      </ControlGroup>

      <Card>
        <SdHeading5 lightBackground>Match</SdHeading5>

        <HTMLSelect
          className="mb-2"
          value={matchType}
          onChange={(item) => {
            setMatchType(item.target.value);
          }}
        >
          <option value="all">All</option>
          <option value="any">Any</option>
        </HTMLSelect>

        {labels.map((label, labelIdx) => (
          <React.Fragment key={`label-${label.id}`}>
            <div className="flex mb-2">
              <ControlGroup fill vertical={false}>
                <InputGroup
                  placeholder="label name"
                  value={label.name}
                  onChange={(event) => {
                    setLabels(
                      produce(labels, (draft) => {
                        draft[labelIdx].name = event.target.value;
                      })
                    );
                  }}
                />
                <InputGroup
                  placeholder="value"
                  value={label.value}
                  onChange={(event) => {
                    setLabels(
                      produce(labels, (draft) => {
                        draft[labelIdx].value = event.target.value;
                      })
                    );
                  }}
                />
              </ControlGroup>
              {/*
             To prevent the UI from shifting due to buttons being added / removed
             when there is 1 or more than 1 label, always show the remove button
             but disable it when there's only 1 label.
             */}
              <SdButton
                className="ml-4"
                disabled={labels.length === 1}
                icon="minus"
                onClick={() => {
                  setLabels(
                    produce(labels, (draft) => {
                      draft.splice(labelIdx, 1);
                    })
                  );
                }}
              />
            </div>
            {labelErrors.has(labelIdx) && (
              <ValidationError>{labelErrors.get(labelIdx)}</ValidationError>
            )}
          </React.Fragment>
        ))}

        <SdButton
          icon="plus"
          onClick={() => {
            setLabels(
              produce(labels, (draft) => {
                draft.push({
                  id: nextId.current++,
                  name: "",
                  value: "",
                });
              })
            );
          }}
        >
          Add
        </SdButton>
      </Card>

      <Card className="mt-4 mb-4">
        <SdHeading5 lightBackground>Endpoints</SdHeading5>

        {endpoints.map((endpoint, endpointIdx) => (
          <React.Fragment key={`endpoint-${endpoint.id}`}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <ControlGroup vertical fill>
                <InputGroup
                  placeholder="name"
                  fill
                  value={endpoint.name}
                  onChange={(event) => {
                    setEndpoints(
                      produce(endpoints, (draft) => {
                        draft[endpointIdx].name = event.target.value;
                      })
                    );
                  }}
                />
                <div style={{ minHeight: "0.5rem" }} />
                <EndpointSelect
                  items={clusterEndpoints}
                  onChange={(protocol: string, hostname: string) => {
                    setEndpoints(
                      produce(endpoints, (draft) => {
                        draft[endpointIdx].url = `${protocol}://${hostname}`;
                      })
                    );
                  }}
                  selectedProtocol={endpoint.url?.split("://")?.[0]}
                  selectedHost={endpoint.url?.split("://")?.[1]}
                />
                <div style={{ minHeight: "0.5rem" }} />
              </ControlGroup>
              <div
                className="ml-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SdButton
                  icon="minus"
                  onClick={() => {
                    setEndpoints(
                      produce(endpoints, (draft) => {
                        draft.splice(endpointIdx, 1);
                      })
                    );
                  }}
                />
              </div>
            </div>
            {endpointErrors.has(endpointIdx) && (
              <ValidationError>
                {endpointErrors.get(endpointIdx)}
              </ValidationError>
            )}
            {endpointIdx + 1 < endpoints.length && (
              <>
                <Spacer />
                <Spacer />
              </>
            )}
          </React.Fragment>
        ))}
        <SdButton
          icon="plus"
          onClick={() => {
            setEndpoints(
              produce(endpoints, (draft) => {
                draft.push({
                  id: nextId.current++,
                  name: "",
                  url: "",
                });
              })
            );
          }}
        >
          Add
        </SdButton>
      </Card>

      <Card className="mt-4 mb-4">
        <SdHeading5 lightBackground>TTL</SdHeading5>

        <Checkbox
          checked={ttl.enabled}
          label="Enable TTL"
          onChange={() =>
            onTTLChange("enabled", (prev) => ({
              ...prev,
              enabled: !prev.enabled,
            }))
          }
        />

        {ttl.enabled && (
          <TTLSelector
            policiesOptions={policies}
            onChangeTTL={onTTLChange}
            ttl={ttl}
          />
        )}
      </Card>

      <div style={{ maxWidth: 100 }}>
        <SdButton
          className="mt-4 mb-4"
          disabled={isSaveDisabled}
          onClick={onRouteGroupSave}
        >
          Save
        </SdButton>
      </div>
      {apiError && (
        <span>
          <ValidationError>{apiError}</ValidationError>
        </span>
      )}
    </div>
  );
};

export default CreateRouteGroup;
