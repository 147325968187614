import React from "react";
import { PanelResizeHandle } from "react-resizable-panels";
import style from "./CustomPanelResizeHandle.module.css";

type CustomPanelResizeHandleProps = {
  direction: "horizontal" | "vertical";
};

const CustomPanelResizeHandle: React.FC<CustomPanelResizeHandleProps> = ({
  direction,
}) => {
  const rotation =
    direction === "horizontal" ? "rotate(90deg)" : "rotate(0deg)";

  return (
    <PanelResizeHandle>
      <div className={style.resizeHandle} data-direction={direction}>
        <div>
          <svg
            style={{ transform: rotation }}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 20 20"
          >
            <circle cx="6" cy="7" r="2" />
            <circle cx="12" cy="7" r="2" />
            <circle cx="18" cy="7" r="2" />
            <circle cx="6" cy="12" r="2" />
            <circle cx="12" cy="12" r="2" />
            <circle cx="6" cy="12" r="2" />
            <circle cx="18" cy="12" r="2" />
          </svg>
        </div>
      </div>
    </PanelResizeHandle>
  );
};

export default CustomPanelResizeHandle;
