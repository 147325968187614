import { type ApiError } from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";
import type { Message, Thread } from "../@types/sd/assistant";

export const CREATE_ASSISTANT_THREAD_API_NAME =
  "api_mutation:create-assistant-thread";

export const ADD_MESSAGE_TO_THREAD_API_NAME =
  "api_mutation:add-message-assistant-thread";

export const useCreateAssistantThread = (
  onSaved: (thread: Thread) => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<Thread>(
    CREATE_ASSISTANT_THREAD_API_NAME,
    "POST",
    [],
    onSaved,
    onError
  );

type UserMessage = Pick<Message, "content">;

export const useAddMessageToThread = (
  onSaved: () => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<Message, UserMessage>(
    ADD_MESSAGE_TO_THREAD_API_NAME,
    "POST",
    [],
    onSaved,
    onError
  );
