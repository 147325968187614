import React, { useMemo } from "react";
import type { TrafficDiffFinding } from "../../../../../@types/sd/findings";
import ServiceDetail from "./ServiceDetail/ServiceDetail";
import { groupFindingsByKey } from "./util";

interface Props {
  findings: TrafficDiffFinding[];
}

const GroupedView: React.FC<Props> = ({ findings }) => {
  const groupFindings = useMemo(
    () => groupFindingsByKey(findings, (f) => f.pairingID),
    [findings]
  );

  const sortedGroupFindings = useMemo(
    () =>
      Object.entries(groupFindings).sort(
        ([, f1], [, f2]) => f2.length - f1.length
      ),
    [findings, groupFindings]
  );

  return (
    <div>
      {sortedGroupFindings.map(([pairingID, f]) => (
        <ServiceDetail key={pairingID} findings={f} testScript="" />
      ))}
    </div>
  );
};

export default GroupedView;
