import React, { useMemo } from "react";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";
import { RxCrossCircled } from "react-icons/rx";
import { CiCircleCheck } from "react-icons/ci";
import type { IconName } from "@blueprintjs/icons";
import type {
  TestExecutionStatus,
} from "../../../../../@types/sd/testexecutions";
import styles from "./SummaryExecutions.module.css";
import type {
  AggregatedChecks,
  AggregatedDiffs,
  AggregatedExecutions,
} from "./utils";
import { aggregateChecks, aggregateDiffs, aggregateExecutions } from "./utils";
import { getExtraDetailsForDiffRendering } from "../TestCollapseTitle/utils";

type SummaryExecutionsProps = {
  executions: TestExecutionStatus[];
};

const TestsSummary = ({
  total,
  aggregated,
}: {
  total: number;
  aggregated: AggregatedExecutions;
}) => {
  const { running, pending, failed, succeeded } = aggregated;

  let icon: React.ComponentProps<typeof Icon> = {
    icon: "symbol-circle" as IconName,
    className: styles.running,
  };

  if (succeeded === total) {
    icon = {
      icon: <CiCircleCheck className={styles.succeeded} size={20} />,
    };
  } else if (failed > 0) {
    icon = {
      icon: <RxCrossCircled className={styles.failed} size={20} />,
    };
  }

  return (
    <div className={classNames(styles.summary_card)}>
      <h3>Tests Status</h3>
      <div className={classNames(styles.summary_details, styles.tests_details)}>
        <div className={styles.left_details}>
          <span>
            <Icon {...icon} />
            {succeeded}/{total}
          </span>
          <span>Tests Completed</span>
        </div>

        <div className={styles.right_details}>
          {(pending > 0 || running > 0) && (
            <div className={classNames(styles.status_pending)}>
              {pending + running}
              <span> Pending</span>
            </div>
          )}
          {failed > 0 && (
            <div className={classNames(styles.status_failed)}>
              {failed}
              <span> Failed</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DiffSummary = ({ aggregated }: { aggregated: AggregatedDiffs }) => {
  const {
    totalDiff,
    count: { red, yellow, green },
  } = aggregated;

  const extraDetails = getExtraDetailsForDiffRendering(
    {
      findingsCount: {
        medium: yellow,
        low: green,
        high: red,
      },
      testType: "diff",
    },
    20
  );

  return (
    <div className={classNames(styles.summary_card)}>
      <h3>Differences Found</h3>
      <div className={classNames(styles.summary_details, styles.diff_details)}>
        <div className={styles.left_details}>
          <span>
            <Icon
              icon={extraDetails.icon.icon}
              className={extraDetails.icon.className}
            />
            {totalDiff}
          </span>
          <span>Total Differences</span>
        </div>

        <div className={styles.right_details}>
          {red > 0 && (
            <span
              className={classNames(
                styles.description_diff,
                styles.description_diff_high
              )}
            >
              {red}
              <span> high</span>
            </span>
          )}
          {yellow > 0 && (
            <span
              className={classNames(
                styles.description_diff,
                styles.description_diff_medium
              )}
            >
              {yellow}
              <span> medium</span>
            </span>
          )}
          {green > 0 && (
            <span
              className={classNames(
                styles.description_diff,
                styles.description_diff_medium
              )}
            >
              {green}
              <span> low</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const ChecksSummary = ({ aggregated }: { aggregated: AggregatedChecks }) => {
  const {
    totalChecks,
    count: { pass, fail },
  } = aggregated;

  const hasErrors = fail > 0;

  return (
    <div className={classNames(styles.summary_card)}>
      <h3>Checks Results</h3>
      <span className={styles.summary_details}>
        <div className={styles.left_details}>
          <span>
            <Icon
              icon={
                hasErrors ? (
                  <RxCrossCircled className={styles.failed} size={20} />
                ) : (
                  <CiCircleCheck className={styles.succeeded} size={20} />
                )
              }
            />
            {pass}/{totalChecks}
          </span>
          <span>Checks Passed</span>
        </div>
      </span>
    </div>
  );
};

const SummaryExecutions = ({ executions }: SummaryExecutionsProps) => {
  const aggregatedExecutions = useMemo(
    () => aggregateExecutions(executions),
    [executions]
  );

  const aggregatedDiffs = useMemo(
    () => aggregateDiffs(executions),
    [executions]
  );

  const aggregatedChecks = useMemo(
    () => aggregateChecks(executions),
    [executions]
  );

  return (
    <div className={styles.container}>
      {/* <h2>Summary</h2> */}
      <div className={styles.summary}>
        <TestsSummary
          total={executions.length}
          aggregated={aggregatedExecutions}
        />
        <DiffSummary aggregated={aggregatedDiffs} />
        <ChecksSummary aggregated={aggregatedChecks} />
      </div>
    </div>
  );
};

export default SummaryExecutions;
