/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { MultiSelect } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";

type FilterLabel = {
  key: string;
  value: string;
};

interface Props {
  labels: FilterLabel[];
  setLabels: (v: FilterLabel[]) => void;
}

const FilterByLabels: React.FunctionComponent<Props> = ({
  labels,
  setLabels,
}) => (
  <MultiSelect
    fill
    resetOnSelect
    placeholder="foo:bar, ..."
    tagRenderer={(t) => {
      if (!t.key || !t.value) {
        return null;
      }
      return `${t.key}: ${t.value}`;
    }}
    items={[]}
    selectedItems={labels}
    noResults="Start typing your label. Eg: team:payments"
    itemsEqual={(l: FilterLabel, r: FilterLabel) => l.key === r.key}
    onItemSelect={(t: FilterLabel) => {
      const index = labels.findIndex(
        (existingLabel) => t.key === existingLabel.key
      );
      if (index > -1) {
        const newLabels = [...labels];
        newLabels[index] = {
          key: t.key,
          value: t.value,
        };
        setLabels(newLabels);
        return;
      }
      setLabels([...labels, { key: t.key, value: t.value }]);
    }}
    onRemove={(_t, i) => {
      const newLabels = [...labels];
      newLabels.splice(i, 1);
      // Clean up any label entries that were added from a malformed input such as "foo:".
      for (let j = newLabels.length - 1; j >= 0; j--) {
        const { key, value } = newLabels[j];
        if (!key || !value) {
          newLabels.splice(j, 1);
        }
      }
      setLabels(newLabels);
    }}
    itemRenderer={(t: FilterLabel, { modifiers, handleClick }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      if (t.key === "" || t.value === "") {
        return null;
      }
      return (
        <MenuItem
          key={t.key}
          label={`${t.key}: ${t.value}`}
          onClick={handleClick}
          text={`${t.key}: ${t.value}`}
          shouldDismissPopover={false}
        />
      );
    }}
    createNewItemFromQuery={(query) => {
      const firstColon = query.indexOf(":");
      if (firstColon === -1) {
        return {
          key: "",
          value: "",
        };
      }
      const key = query.slice(0, firstColon);
      const value = query.slice(firstColon + 1).trim();
      return {
        key,
        value,
      };
    }}
    createNewItemRenderer={(query, active, handleClick) => (
      <MenuItem
        text={query}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    )}
  />
);

export default FilterByLabels;
