import React from "react";

import { useNavigate } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import styles from "./NewUser.module.css";
import logo from "../../components/Interstitial/logo";
import SdButton from "../../components/theming/SdButton";
import HistoryLink from "../../components/HistoryLink";
import { useAuth } from "../../contexts/AuthContext";
import Loading from "../../components/Loading/Loading";
import AuthError from "../../components/Auth/Error";

const NewUser: React.FunctionComponent = () => {
  const { loading, error, user } = useAuth();
  const navigate = useNavigate();
  if (error) {
    return <AuthError />;
  }
  if (loading) {
    return (
      <div className={styles.page}>
        <div className={styles.container}>
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img src={logo} alt="Signadot" className={styles.logo} />
        <div className={styles.elements}>
          <div>
            <span className={styles.welcome_text}>Welcome</span>
            <span className={styles.email_text}>
              {user?.email ?? "new user"}
            </span>
          </div>
          <div className="flex justify-center">
            <SdButton
              onClick={() => {
                navigate("/orgs/setup");
              }}
              className={styles.create_new}
            >
              Create a new Organization
            </SdButton>
          </div>
          <div className={styles.relative}>
            {/* The "separator line" container */}
            <div className={styles.absolute_line}>
              {/* A simple <hr> or any line element */}
              <hr className={styles.separator} />
            </div>

            {/* The text in the middle */}
            <div className={styles.center_text}>
              <span className={styles.text_background}>Or</span>
            </div>
          </div>
          <HistoryLink url="/users/new/waiting" className={styles.waiting_invite_text}>
            Waiting for an Invite
          </HistoryLink>
          <div className={styles.logout}>
            <button
              onClick={() => {
                navigate("/signout");
              }}
            >
              <Icon icon="log-out" />
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
