import { useAuth } from "../contexts/AuthContext";

type FeatureFlag = "assistant-panel-ai";

const useFeatureFlag = (featureName: FeatureFlag) => {
  const { enabledFeatureFlags } = useAuth();

  return {
    featureIsAvailable: enabledFeatureFlags && enabledFeatureFlags[featureName],
  };
};

export default useFeatureFlag;
