import type { QueryKey } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";
import { generatePath } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export interface ApiError {
  message: string;
  response: {
    status: number;
    data: {
      error: string;
    };
  };
}

type Transformer<TransformDataT, TransformedDataT> =
  | ((arg: TransformDataT) => TransformedDataT)
  | undefined;

const baseURL = process.env.VITE_API_BASE_URL;
axios.defaults.baseURL = baseURL;

const useApi = <ResponseDataT, TransformedDataT = ResponseDataT>(
  name: QueryKey,
  url: string | (() => string),
  config = {},
  transformer: Transformer<ResponseDataT, TransformedDataT> = undefined
) => {
  const { AuthToken, org, loading } = useAuth();

  let apiConfig = config;
  if (loading) {
    apiConfig = {
      ...config,
      enabled: false,
    };
  }
  return useQuery<ResponseDataT, ApiError>(
    name,
    async () => {
      // set orgName parameter in URL if needed.
      let expandedUrl;
      if (typeof url === "function") {
        expandedUrl = generatePath(url(), { orgName: org!.name });
      } else {
        expandedUrl = generatePath(url, { orgName: org!.name });
      }
      return axios
        .get(expandedUrl, {
          headers: {
            Authorization: `Bearer ${AuthToken}`,
          },
        })
        .then((result) =>
          transformer ? transformer(result.data) : result.data
        );
    },
    apiConfig
  );
};

export default useApi;
