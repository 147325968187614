import React, { useEffect, useRef } from "react";

import { Outlet } from "react-router-dom";
import {
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from "react-resizable-panels";
import { Icon } from "@blueprintjs/core";
import { BsChatLeftDots } from "react-icons/bs";
import classNames from "classnames";
import { Sidebar } from "./Sidebar/Sidebar";
import { useAuth } from "../../contexts/AuthContext";
import Loading from "../Loading/Loading";
import AuthError from "../Auth/Error";
import Error from "../Error/Error";
import styles from "./Layout.module.css";
import TopBar from "./TopBar";
import BillingBanner from "../../pages/Billing/BillingBanner";
import useFeatureFlag from "../../hooks/UseFeatureFlag";
import CustomPanelResizeHandle from "../CustomPanelResizeHandle/CustomPanelResizeHandle";
import useDisclosure from "../../hooks/UseDisclosure";
import Chat from "../Chat";

const MIN_VIEWPORT_WIDTH_PX = 800;

type ContextPanelProps = {
  isCollapsed: boolean;
  onToggle: () => void;
};

const ContextPanel = ({ isCollapsed, onToggle }: ContextPanelProps) => {
  if (isCollapsed) {
    return (
      <div className={styles.panel_section} onClick={onToggle}>
        <Icon icon={<BsChatLeftDots />} />
        <span>Assistant</span>
      </div>
    );
  }

  return (
    <div className={styles.panel_content}>
      <Chat onToggle={onToggle} />
    </div>
  );
};

const ProtectedRouteLayout: React.FunctionComponent = () => {
  const { org, loading, error } = useAuth();
  
  const [width, setWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : MIN_VIEWPORT_WIDTH_PX
  );

  const featureFlag = useFeatureFlag("assistant-panel-ai");
  const panelContextCollapsed = useDisclosure(false);
  const contextPanelRef = useRef<ImperativePanelHandle>(null);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (panelContextCollapsed.isOpen) {
      contextPanelRef.current?.resize(20);
    } else {
      contextPanelRef.current?.resize(2);
    }
  }, [panelContextCollapsed.isOpen]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AuthError message={error} />;
  }

  if (!org) {
    return <Error text="No organization found" />;
  }

  if (width < MIN_VIEWPORT_WIDTH_PX) {
    return (
      <Error
        text={`Viewport must be ${MIN_VIEWPORT_WIDTH_PX}px or wider to use the Signadot Dashboard`}
      />
    );
  }

  return (
    <PanelGroup direction="horizontal">
      <Panel>
        <div className="flex flex-col h-screen overflow-hidden font-sans">
          <BillingBanner />
          <div className="flex h-full">
            <Sidebar org={org} />
            <div className="overflow-y-auto h-full w-full relative">
              <div className="sticky top-0 z-20">
                <TopBar />
              </div>
              <main className={`content ${styles.main}`}>
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </Panel>
      {featureFlag.featureIsAvailable && (
        <>
          <CustomPanelResizeHandle direction="horizontal" />

          <Panel
            ref={contextPanelRef}
            minSize={20}
            maxSize={40}
            collapsedSize={2}
            collapsible
            onCollapse={panelContextCollapsed.close}
            onExpand={panelContextCollapsed.open}
          >
            <div
              className={classNames(
                "flex flex-col h-screen overflow-hidden font-sans",
                styles.panel
              )}
            >
              <ContextPanel
                isCollapsed={!panelContextCollapsed.isOpen}
                onToggle={panelContextCollapsed.toggle}
              />
            </div>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
};

export default ProtectedRouteLayout;
