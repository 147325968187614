import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import styles from "./NewUser.module.css";
import logo from "../../components/Interstitial/logo";

const Waiting: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img src={logo} alt="Signadot" className={styles.logo} />
        <div className={styles.elements}>
          <div>
            Please contact the person who created the organization for an invite
            to gain access.
          </div>
          <div className={styles.logout}>
            <button
              onClick={() => {
                navigate("/signout");
              }}
            >
              <Icon icon="log-out" />
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Waiting;
