import type { ContainerLogs } from "./LogPanel";
import type { LogItem } from "../../../components/Logs/Logs";

// eslint-disable-next-line import/prefer-default-export
export const mergeLogs = (
  prevLogs: ContainerLogs[] = [],
  newLogs: ContainerLogs[] = []
): ContainerLogs[] => {
  const logMap = new Map<string, LogItem[]>();

  [...prevLogs, ...newLogs].forEach(({ container, logs }) => {
    if (logs) {
      logMap.set(container, (logMap.get(container) || []).concat(logs));
    }
  });

  return Array.from(logMap.entries()).map(([container, logs]) => ({
    container,
    logs,
  }));
};
