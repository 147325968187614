import React from "react";
import {
  FormGroup,
  InputGroup,
  Intent,
  OverlayToaster,
  Spinner,
} from "@blueprintjs/core";
import style from "./CreateOrg.module.css";
import logo from "../../components/Interstitial/logo";
import SdButton from "../../components/theming/SdButton";
import { useCreateOrgApi } from "../../api/SetupApi";

const toaster = OverlayToaster.create();

const CreateOrgInterstitial = () => {
  const [orgDisplayName, setOrgDisplayName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const formReady = () =>
    orgDisplayName && firstName && lastName && acceptTerms;

  const [createOrg, createOrgApi] = useCreateOrgApi();
  const handleCreate = () => {
    setSubmitting(true);
    createOrg({
      orgDisplayName,
      firstName,
      lastName,
    });
  };

  if (createOrgApi.error) {
    toaster.show({
      message: createOrgApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createOrgApi.reset();
    setSubmitting(false);
  } else if (createOrgApi.isSuccess) {
    toaster.show({
      message: "Organization created",
      intent: Intent.SUCCESS,
    });
    window.location.href = "/";
  }

  return (
    <div className={style.page}>
      <div className={style.container}>
        <img src={logo} alt="Signadot" className={style.logo} />
        <p className={style.title}>Set up your organization</p>
        <div className={style.form}>
          <FormGroup
            label="Organization Name"
            labelFor="text-input"
            inline
            className={style.formGroup}
          >
            <InputGroup
              id="text-input"
              value={orgDisplayName}
              placeholder=""
              onChange={(e) => setOrgDisplayName(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Your First Name"
            labelFor="text-input"
            inline
            className={style.formGroup}
          >
            <InputGroup
              id="text-input"
              value={firstName}
              placeholder=""
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Your Last Name"
            labelFor="text-input"
            inline
            className={style.formGroup}
          >
            <InputGroup
              id="text-input"
              value={lastName}
              placeholder=""
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <div className={style.acceptTerms}>
            <input
              type="checkbox"
              value={`${acceptTerms}`}
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <span>
              {" "}
              I accept the{" "}
              <a href="https://www.signadot.com/terms-of-service" target="_tos">
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://signadot.com/privacy-policy/"
                target="privacy-policy"
              >
                Privacy Policy
              </a>
              .
            </span>
          </div>
          {!isSubmitting && (
            <SdButton onClick={handleCreate} disabled={!formReady()} className={style.create_org}>
              Create Organization
            </SdButton>
          )}
          {isSubmitting && <Spinner />}
        </div>
      </div>
    </div>
  );
};
export default CreateOrgInterstitial;
