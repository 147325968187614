import type { TestExecution } from "../../../../../../@types/sd/testexecutions";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../../../../@types/sd/testexecutions.d.ts";

export const runPrefix = (execution: TestExecution) => {
  switch (execution.status.phase) {
    case TestExecutionStatusPhases.InProgress:
      return "Running";
    case TestExecutionStatusPhases.Succeeded:
      return "Ran";
    case TestExecutionStatusPhases.Failed:
      return "Ran";
    default:
      return "Run";
  }
};

export const getRoutingContext = (
  execution: TestExecution
): { value: string; context: "baseline" | "sandbox" | "routegroup" } => {
  const routingContext = execution.spec.executionContext.routing;

  if (routingContext?.sandbox) {
    return {
      context: "sandbox",
      value: routingContext.sandbox,
    };
  }

  if (routingContext?.routegroup) {
    return {
      context: "routegroup",
      value: routingContext.routegroup,
    };
  }

  return {
    value: "",
    context: "baseline",
  };
};
