import axios from "axios";
import { generatePath } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const baseURL = process.env.VITE_API_BASE_URL;
axios.defaults.baseURL = baseURL;

// Utility function to correct the file extension based on the content type.
export const correctFileExtension = (
  filename: string,
  contentType: string
): string => {
  const extensionMap: { [key: string]: string } = {
    "image/png": ".png",
    "image/jpeg": ".jpg",
    "image/gif": ".gif",
    "image/bmp": ".bmp",
    "image/webp": ".webp",
    "application/pdf": ".pdf",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/vnd.ms-powerpoint": ".ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      ".pptx",
    "application/zip": ".zip",
    "application/x-rar-compressed": ".rar",
    "application/x-tar": ".tar",
    "application/x-7z-compressed": ".7z",
    "application/json": ".json",
    "text/plain": ".txt",
    "text/html": ".html",
    "text/css": ".css",
    "text/javascript": ".js",
    "application/javascript": ".js",
    "application/xml": ".xml",
    "audio/mpeg": ".mp3",
    "audio/ogg": ".ogg",
    "audio/wav": ".wav",
    "audio/webm": ".webm",
    "video/mp4": ".mp4",
    "video/mpeg": ".mpeg",
    "video/ogg": ".ogv",
    "video/webm": ".webm",
    "video/x-msvideo": ".avi",
  };

  const extension = extensionMap[contentType];

  if (!extension) {
    return filename;
  }

  const nameWithoutExtension = filename.split(".").slice(0, -1).join(".");
  return nameWithoutExtension
    ? `${nameWithoutExtension}${extension}`
    : `${filename}${extension}`;
};

const useDownloadFile = () => {
  const { AuthToken, org } = useAuth();

  const download = (
    url: string | (() => string),
    filename: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    let expandedUrl;
    if (typeof url === "function") {
      expandedUrl = generatePath(url(), { orgName: org!.name });
    } else {
      expandedUrl = generatePath(url, { orgName: org!.name });
    }

    axios
      .get(expandedUrl, {
        headers: {
          Authorization: `Bearer ${AuthToken}`,
        },
        responseType: "blob", // Ensure the response is treated as a blob
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        const correctedFilename = correctFileExtension(filename, contentType);

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        const a: HTMLAnchorElement = document.createElement("a");
        a.href = objectURL;
        a.download = correctedFilename;
        document.body.appendChild(a); // Append the anchor to the body
        a.click();
        window.URL.revokeObjectURL(objectURL);
        document.body.removeChild(a); // Remove the anchor from the body
        onSuccess();
      })
      .catch(() => {
        onError("There was an error downloading the file.");
      });
  };

  return { download };
};

export default useDownloadFile;
