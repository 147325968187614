/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import {
  Alert,
  Callout,
  Card,
  H5,
  HTMLSelect,
  Intent,
  Menu,
  MenuItem,
  OverlayToaster,
  Popover,
  Position,
  Switch,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { SdHeading1, SdHeading2 } from "../theming/SdHeading";

import styles from "./Users.module.css";
import SdInput from "../theming/SdInput";
import SdButton from "../theming/SdButton";
import Spacer from "../Util/Util";
import type { User } from "../../api/UsersApi";
import {
  useDeleteUserApi,
  UserRole,
  useUpdateUserRole,
  useUsersApi,
} from "../../api/UsersApi";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import PaginatedTable from "../PaginatedTable/PaginatedTable";
import type { Invite } from "../../api/InvitesApi";
import {
  useCreateInviteApi,
  useDeleteInviteApi,
  useInvitesApi,
} from "../../api/InvitesApi";
import { useAuth } from "../../contexts/AuthContext";
import { useAuthorizedDomainsApi } from "../../api/OrgApi";
import AuthSettings from "../../pages/Settings/AuthSettings";
import { useGetAuthSettings } from "../../pages/Settings/AuthSettings/AuthSettingsApi";
import IconWithTooltip from "../theming/IconWithTooltip";

type FormattedUser = Omit<User, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
  actions: JSX.Element | null;
  menu: {
    text: string;
    component: JSX.Element;
  };
};

interface FormattedInvite extends Omit<Invite, "createdAt"> {
  createdAt: React.ReactNode;
  actions: React.ReactNode;
}

const toaster = OverlayToaster.create();

const invitesColumns = [
  {
    Header: "ID",
    accessor: "id",
    show: false,
  },
  {
    Header: "Email",
    accessor: "email",
    search: true,
  },
  {
    Header: "Role",
    accessor: "role",
    search: true,
  },
  {
    Header: "Invite Sent",
    accessor: "createdAt",
    search: true,
  },
  {
    Header: "Action",
    accessor: "actions",
  },
];

const usersColumns = [
  {
    Header: "ID",
    accessor: "id",
    show: false,
  },
  {
    Header: "Email",
    accessor: "email",
    search: true,
  },
  {
    Header: "Role",
    accessor: "role",
    search: true,
  },
  {
    Header: "Created",
    accessor: "createdAt",
    search: true,
  },
  {
    Header: "Action",
    accessor: "actions",
  },
  {
    Header: "",
    accessor: "menu",
  },
];

const formatUsers = (
  updateUserRole: (userId: string, role: UserRole) => void,
  users: User[],
  self?: User
): FormattedUser[] =>
  users.map((user) => ({
    ...user,
    createdAt: new Date(user.createdAt).toDateString(),
    updatedAt: new Date(user.updatedAt).toDateString(),
    actions:
      user.id !== self?.id ? (
        <IconWithTooltip text="Delete" icon="trash" />
      ) : null,
    menu: {
      text: "",
      component: (
        <div>
          <Popover
            content={
              <Menu>
                <MenuItem
                  text={`Make ${
                    user.role === UserRole.ADMIN
                      ? UserRole.MEMBER
                      : UserRole.ADMIN
                  }`}
                  onClick={() =>
                    updateUserRole(
                      user.id,
                      user.role === UserRole.ADMIN
                        ? UserRole.MEMBER
                        : UserRole.ADMIN
                    )
                  }
                />
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <HiOutlineDotsHorizontal size={20} />
          </Popover>
        </div>
      ),
    },
  }));

const formatInvites = (invites: Invite[]): FormattedInvite[] =>
  invites.map((invite) => ({
    ...invite,
    createdAt: new Date(invite.createdAt).toDateString(),
    actions: <IconWithTooltip text="Delete" icon="trash" />,
  }));

const Users: React.FunctionComponent = () => {
  const { user: selfUser } = useAuth();
  const { data: authSettingsData } = useGetAuthSettings();
  const ssoEnabled = !!authSettingsData?.sso;
  const authorizedDomainsApi = useAuthorizedDomainsApi();
  const {
    enabled: UsesAuthorizedEmailDomains,
    setEnabled: setUseAuthorizedEmailDomains,
    authorizedDomains,
    setAuthorizedDomains,
    allDomains,
    error: authorizedEmailDomainsError,
    enableAuthorizedDomainApi,
  } = authorizedDomainsApi;
  const selectableItems = useMemo((): string[] => {
    const selectedItemSet = new Set<string>();
    for (let i = 0; i < authorizedDomains.length; i++) {
      selectedItemSet.add(authorizedDomains[i]);
    }
    const ret = [];
    for (let i = 0; i < allDomains.length; i++) {
      const domain = allDomains[i];
      if (!selectedItemSet.has(domain)) {
        ret.push(domain);
      }
    }
    return ret;
  }, [authorizedDomains]);

  const [updateUserRole, updateUserRoleApi] = useUpdateUserRole();
  if (updateUserRoleApi.error) {
    toaster.show({
      message: updateUserRoleApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    updateUserRoleApi.reset();
  } else if (updateUserRoleApi.isSuccess) {
    toaster.show({
      message: "Role updated",
      intent: Intent.SUCCESS,
    });
    updateUserRoleApi.reset();
  }

  const { users, error, loading } = useUsersApi();
  const userTableData = useMemo(() => {
    if (!Array.isArray(users)) {
      return [];
    }
    return formatUsers(updateUserRole, users, selfUser);
  }, [users]);

  const allInvitesResult = useInvitesApi();
  const invitesTableData = useMemo(() => {
    if (!Array.isArray(allInvitesResult.invites)) {
      return [];
    }
    return formatInvites(allInvitesResult.invites);
  }, [allInvitesResult.invites]);

  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState<string>(UserRole.MEMBER);

  const [createInvite, createInviteApi] = useCreateInviteApi();
  const [deleteInvite, deleteInviteApi] = useDeleteInviteApi();

  const [deleteUser, deleteUserAPI] = useDeleteUserApi();
  if (deleteUserAPI.error) {
    toaster.show({
      message: deleteUserAPI.error.response.data.error,
      intent: Intent.DANGER,
    });
    deleteUserAPI.reset();
  } else if (deleteUserAPI.isSuccess) {
    toaster.show({
      message: "User deleted",
      intent: Intent.SUCCESS,
    });
    deleteUserAPI.reset();
  }

  const [isDeleteInviteVisible, setDeleteInviteVisible] = useState(false);
  const [isDeleteUserVisible, setDeleteUserVisible] = useState(false);
  const [userBeingDeleted, setUserBeingDeleted] = useState({
    email: "",
    id: "",
  });
  const [inviteBeingDeleted, setInviteBeingDeleted] = useState({
    email: "",
    id: "",
  });

  const handleCreateInvite = () => {
    createInvite(inviteEmail, inviteRole);
    setInviteEmail("");
    setInviteRole(UserRole.MEMBER);
  };

  const handleCreateInviteKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      handleCreateInvite();
    }
  };

  const handleInviteDelete = (row: FormattedInvite) => {
    setDeleteInviteVisible(true);
    setInviteBeingDeleted({
      email: row.email,
      id: row.id,
    });
  };

  const handleUserDelete = (row: FormattedUser) => {
    setDeleteUserVisible(true);
    setUserBeingDeleted({
      email: row.email,
      id: row.id,
    });
  };

  if (createInviteApi.error) {
    toaster.show({
      message: createInviteApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createInviteApi.reset();
  }

  if (enableAuthorizedDomainApi.error) {
    toaster.show({
      message: enableAuthorizedDomainApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    enableAuthorizedDomainApi.reset();
  }

  if (deleteInviteApi.error) {
    toaster.show({
      message: deleteInviteApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    deleteInviteApi.reset();
  } else if (deleteInviteApi.isSuccess) {
    toaster.show({
      message: "User invite deleted",
      intent: Intent.SUCCESS,
    });
    deleteInviteApi.reset();
  }

  if (loading || !users) {
    return <Loading />;
  }
  if (error) {
    return <Error text={error} />;
  }
  return (
    <>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Invite"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteInviteVisible}
        onCancel={() => {
          setDeleteInviteVisible(false);
          setInviteBeingDeleted({
            email: "",
            id: "",
          });
        }}
        onConfirm={() => {
          deleteInvite(inviteBeingDeleted.id);
          setDeleteInviteVisible(false);
          setInviteBeingDeleted({
            email: "",
            id: "",
          });
        }}
      >
        <p>
          Are you sure you wish to delete the pending invite to{" "}
          <b>{inviteBeingDeleted.email}</b>?
        </p>
      </Alert>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Remove User"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteUserVisible}
        onCancel={() => {
          setDeleteUserVisible(false);
          setUserBeingDeleted({
            email: "",
            id: "",
          });
        }}
        onConfirm={() => {
          deleteUser(userBeingDeleted.id);
          setDeleteUserVisible(false);
          setUserBeingDeleted({
            email: "",
            id: "",
          });
        }}
      >
        <p>
          Are you sure you wish to remove the user{" "}
          <b>{userBeingDeleted.email}</b>?
        </p>
      </Alert>

      <SdHeading1 small lightBackground>
        Users
      </SdHeading1>
      <Spacer />
      <Spacer />
      <Spacer />

      <SdHeading2 small lightBackground>
        Invites
      </SdHeading2>
      <Spacer />
      <Spacer />

      <div className={styles.inviteContainer}>
        <SdInput
          placeholder="email"
          value={inviteEmail}
          onInput={(e) => setInviteEmail((e.target as HTMLInputElement).value)}
          onKeyDown={handleCreateInviteKeyDown}
        />
        <span className="ml-3" />
        <HTMLSelect
          onChange={(e) => setInviteRole(e.target.value)}
          defaultValue={UserRole.MEMBER}
        >
          <option value={UserRole.MEMBER}>Member</option>
          <option value={UserRole.ADMIN}>Admin</option>
        </HTMLSelect>
        <span className="ml-3" />
        <SdButton icon="plus" onClick={handleCreateInvite}>
          Invite User
        </SdButton>
      </div>
      <Spacer />
      <Spacer />
      <Spacer />

      {invitesTableData.length > 0 && (
        <>
          <PaginatedTable
            columns={invitesColumns}
            data={invitesTableData}
            onActionCallback={handleInviteDelete}
            enableSearch={false}
            pageSize={3}
          />
          <Spacer />
          <Spacer />
          <Spacer />
        </>
      )}

      <SdHeading2 small lightBackground>
        Users
      </SdHeading2>
      {users.length === 0 && <div>No users</div>}
      {users.length > 0 && (
        <PaginatedTable
          columns={usersColumns}
          data={userTableData}
          onActionCallback={handleUserDelete}
          pageSize={10}
        />
      )}

      <Card className={ssoEnabled ? styles.ssoEnabled : ""}>
        {ssoEnabled && (
          <div>
            <Callout intent={Intent.WARNING} className={styles.callout}>
              Authorized domains not available since the org is configured for
              SSO.
            </Callout>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "1em" }}>
          <H5>Authorized Domains</H5>
          <div className="ml-5" />
          <Switch
            style={{ marginTop: 1 }}
            checked={UsesAuthorizedEmailDomains}
            onChange={() =>
              setUseAuthorizedEmailDomains(!UsesAuthorizedEmailDomains)
            }
            disabled={ssoEnabled}
          />
        </div>
        {!ssoEnabled && (
          <p>
            Select email domains that are automatically authorized to join your
            organization. Note that you must select from the email domains of
            users that are already a part of your organization.
          </p>
        )}
        <Spacer />
        {UsesAuthorizedEmailDomains && !authorizedEmailDomainsError && (
          <div style={{ display: "flex", maxWidth: "28rem" }}>
            {ssoEnabled ? (
              <div>{authorizedDomains?.join(", ")}</div>
            ) : (
              <MultiSelect
                fill
                placeholder="Select one or more domains..."
                tagRenderer={(d) => d}
                items={selectableItems}
                selectedItems={authorizedDomains}
                noResults="No available domains to authorize"
                itemsEqual={(l, r) => l === r}
                onItemSelect={(d) => {
                  setAuthorizedDomains([...authorizedDomains, d]);
                }}
                onRemove={(_d, i) => {
                  const newDomains = [...authorizedDomains];
                  newDomains.splice(i, 1);
                  setAuthorizedDomains(newDomains);
                }}
                itemRenderer={(d: string, { modifiers, handleClick }) => {
                  if (!modifiers.matchesPredicate) {
                    return null;
                  }
                  return (
                    <MenuItem
                      key={d}
                      label={d}
                      onClick={handleClick}
                      text={d}
                      shouldDismissPopover={false}
                    />
                  );
                }}
              />
            )}
          </div>
        )}
        {UsesAuthorizedEmailDomains && !!authorizedEmailDomainsError && (
          <Error text={authorizedEmailDomainsError} />
        )}
      </Card>

      <AuthSettings />
    </>
  );
};

export default Users;
