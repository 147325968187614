import React, { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import _ from "lodash";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { SiKubernetes } from "react-icons/si";
import { GrDocumentTest } from "react-icons/gr";
import BaseWidget from "../BaseWidget";
import styles from "./GettingStarted.module.css";
import useApi from "../../../../hooks/UseApi";

type StepAction = {
  label: string;
  do: () => void;
  // eslint-disable-next-line no-use-before-define
  button?: (step: Step) => React.ReactNode;
};

type Step = {
  title: string;
  isCompleted: boolean;
  description: string;
  action: StepAction;
  dismissOptions?: {
    dismissible: boolean;
    onDismiss: () => void;
  };
  leftIcon?: React.ReactNode;
};

const allStepsCompleted = (steps: Step[]): boolean =>
  steps.some((s) => !s.isCompleted);

const StepRender = ({
  title,
  isCompleted,
  description,
  action,
  dismissOptions,
  leftIcon,
}: Step) => (
  <div className={styles.step_container} data-step-completed={isCompleted}>
    <div className={classNames(styles.icon)}>{leftIcon}</div>
    <div className={styles.step_body}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
    <div className={styles.right}>
      {action.button ? (
        action.button({ action, title, description, isCompleted })
      ) : (
        <>
          {!isCompleted && dismissOptions?.dismissible && (
            <RxCrossCircled
              size={20}
              onClick={dismissOptions.onDismiss}
              className={styles.action_dismiss}
            />
          )}
          <button onClick={action.do}>
            {isCompleted ? "Completed" : action.label}
            {!isCompleted && (
              <Icon icon="arrow-right" className={styles.action_icon} />
            )}
          </button>
        </>
      )}
    </div>
  </div>
);

const getLocalKey = (name: "sandbox" | "smart-tests") =>
  `widgets/getting-started/${name}-x`;

const isDismissed = (name: "sandbox" | "smart-tests") =>
  localStorage.getItem(getLocalKey(name)) === "true";

const dismissItem = (name: "sandbox" | "smart-tests") =>
  localStorage.setItem(getLocalKey(name), "true");

const GettingStarted = () => {
  const [stepsState, setStepsState] = useState<{
    sandbox: boolean;
    smartTests: boolean;
  }>({ sandbox: false, smartTests: false });

  const navigate = useNavigate();
  const steps: Step[] = [
    {
      title: "Create Sandbox",
      isCompleted: stepsState.sandbox,
      description: "Write a YAML specification to create a sandbox",
      action: {
        label: "Create",
        do: () => navigate("/sandboxes/create"),
      },
      dismissOptions: {
        dismissible: true,
        onDismiss: () => dismissItem("sandbox"),
      },
      leftIcon: <Icon icon="layers" size={20} />,
    },
    {
      title: "Create Smart Test",
      isCompleted: stepsState.smartTests,
      description:
        "Write a test in Starlark that runs automatically when you create or update a Sandbox and compares it to the baseline.",
      action: {
        label: "Create",
        do: () => navigate("/testing/tests/editor/specification"),
      },
      dismissOptions: {
        dismissible: true,
        onDismiss: () => dismissItem("smart-tests"),
      },
      leftIcon: <GrDocumentTest size={20} />,
    },
  ];

  useEffect(() => {
    const intervalID = setInterval(() => {
      setStepsState(() => ({
        sandbox: isDismissed("sandbox"),
        smartTests: isDismissed("smart-tests"),
      }));
    }, 100);

    return () => clearInterval(intervalID);
  }, []);

  const clusters = useApi("clusters", `/api/v1/orgs/:orgName/clusters`);
  if (clusters.isLoading) {
    return null;
  }

  if (clusters.error && !clusters.data) {
    return null;
  }

  const clusterList = _.get(clusters, ["data", "clusters"]) || [];
  if (clusterList.length === 0) {
    return (
      <BaseWidget title="Getting Started">
        <div className={styles.cluster_steps}>
          <StepRender
            {...{
              isCompleted: false,
              description:
                "Set up the Signadot Operator on your Kubernetes cluster",
              title: "Connect Cluster",
              action: {
                label: "Connect",
                do: () => navigate("/settings/clusters#connect"),
              },
              dismissOptions: {
                dismissible: false,
                onDismiss: () => {},
              },
              leftIcon: <SiKubernetes size={20} />,
            }}
          />
          <div className={styles.separator}>
            <span>or</span>
          </div>
          <StepRender
            {...{
              isCompleted: false,
              description:
                "Provision a pre-configured Kubernetes Cluster to try Signadot",
              title: "Create Playground",
              action: {
                label: "Create",
                do: () => navigate("/settings/clusters#playground"),
              },
              dismissOptions: {
                dismissible: false,
                onDismiss: () => {},
              },
              leftIcon: <GrDocumentTest size={20} />,
            }}
          />
        </div>
      </BaseWidget>
    );
  }

  if (!allStepsCompleted(steps)) {
    return null;
  }

  return (
    <BaseWidget title="Getting Started">
      <div className={styles.steps}>
        {steps
          .filter((s) => !s.isCompleted)
          .map((s) => (
            <>
              <StepRender key={s.title} {...s} />
              <div className={styles.separator} />
            </>
          ))}
      </div>
    </BaseWidget>
  );
};

export default GettingStarted;
