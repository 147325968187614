import type {
  QueryResult,
  TestExecution, TestExecutionStatus,
} from "../../../../@types/sd/testexecutions";

// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../../@types/sd/testexecutions.d.ts";

// eslint-disable-next-line import/prefer-default-export
export const groupByTest = (
  executions: QueryResult[]
): Record<string, TestExecution> => {
  const grouped = {};

  executions.forEach((e) => {
    const execution = e.execution;
    const testName = execution.spec.test;
    if (!grouped[testName]) {
      grouped[testName] = execution;
    }
  });

  return grouped;
};

type groupByTestCompletionReturn = {
  completed: TestExecutionStatus[];
  others: TestExecutionStatus[];
};

// eslint-disable-next-line import/prefer-default-export
export const groupByTestCompletion = (
  executions: TestExecutionStatus[]
): groupByTestCompletionReturn => {
  const groupedExecutions: groupByTestCompletionReturn = {
    completed: [],
    others: [],
  };

  executions.forEach((execution) => {
    switch (execution.phase) {
      case TestExecutionStatusPhases.Succeeded:
        groupedExecutions.completed.push(execution);
        break;
      case TestExecutionStatusPhases.Pending:
      case TestExecutionStatusPhases.InProgress:
      case TestExecutionStatusPhases.Failed:
      case TestExecutionStatusPhases.Canceled:
      default:
        groupedExecutions.others.push(execution);
    }
  });

  return groupedExecutions;
};