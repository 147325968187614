import type { TestExecutionStatusPhases } from "../../../../../@types/sd/testexecutions";
import type {
  StatusColor,
  StatusStandard,
} from "../../../../../components/MultiTabsLayout/MultiTabsLayout";

type ExecutionState = TestExecutionStatusPhases;

// Not too great an approach, but it works for now.
const executionColorMapping: Record<ExecutionState, StatusColor> = {
  canceled: "info",
  failed: "error",
  succeeded: "success",
  in_progress: "warning",
  pending: "note",
};

const titleMapping: Record<ExecutionState, string> = {
  canceled: "Canceled",
  failed: "Failed",
  succeeded: "Succeeded",
  in_progress: "In Progress",
  pending: "Pending",
};

const getExecutionStatus = (status: ExecutionState): StatusStandard => ({
  getText: titleMapping[status],
  getColor: executionColorMapping[status],
});

export default getExecutionStatus;
