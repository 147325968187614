import React from "react";

import { ChatConsumer } from "./ChatConsumer";
import { ChatContextProvider } from "./context/ChatContext";

type ChatProps = {
  onToggle?: () => void;
};

const Chat = ({ onToggle }: ChatProps) => (
  <ChatContextProvider>
    <ChatConsumer onToggle={onToggle} />
  </ChatContextProvider>
);

export default Chat;
