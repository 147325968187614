import React from "react";
import { Button, Card, Dialog, Elevation } from "@blueprintjs/core";
import CopyableText from "../CopyableText";
import { useAuth } from "../../contexts/AuthContext";

interface Props {
  title: string;
  token: string;
  isDialogOpen: boolean;
  onClose: () => void;
}

const OneTimeTokenDialog: React.FunctionComponent<Props> = ({
  title,
  token,
  isDialogOpen,
  onClose,
}) => {
  const { org } = useAuth();

  if (!token || !isDialogOpen) {
    return null;
  }

  const onDialogClose = () => {
    onClose();
  };

  return (
    <Dialog
      title={title}
      isOpen={isDialogOpen}
      onClose={onDialogClose}
      style={{ width: 600 }}
    >
      <Card
        style={{ marginBottom: "-20px" }}
        interactive
        elevation={Elevation.TWO}
      >
        <p>
          <b>Important!</b>
        </p>
        Please copy your token now. You will not be able to view it again.
        <br />
        <br />
        <CopyableText className="mt-2 mb-2 p-4">{token}</CopyableText>
        <br />
        To use this token with the{" "}
        <a
          href="https://www.signadot.com/docs/reference/cli"
          target="_blank"
          rel="noreferrer"
        >
          CLI
        </a>
        , save the following in <code>$HOME/.signadot/config.yaml</code>:
        <br />
        <br />
        <CopyableText className="mt-2 mb-2 p-4">
          org: {org?.name}
          <br />
          api_key: {token}
        </CopyableText>
        <br />
        <Button className="mr-2" onClick={onDialogClose} outlined={false}>
          Close
        </Button>
      </Card>
    </Dialog>
  );
};

export default OneTimeTokenDialog;
