import type { ReactNode } from "react";
import React from "react";
import { SdHeading2 } from "../../SdHeading";
import styles from "./ListSection.module.css";

interface ListSectionProps<T> {
  title: string;
  items?: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
}

const ListSection = <T extends object>({
  title,
  items,
  renderItem,
}: // eslint-disable-next-line react/no-unused-prop-types
ListSectionProps<T> & { children?: ReactNode }) => (
  <div className={styles.container}>
    <SdHeading2 small lightBackground>
      {title}
    </SdHeading2>
    <div className={styles.items}>
      {items && items.length > 0
        ? items!.map((item: T, idx) => renderItem(item, idx))
        : "None"}
    </div>
  </div>
);

export default ListSection;
