import * as React from "react";
import { useEffect, useRef, useState } from "react";

import Masonry from "react-responsive-masonry";
import Spacer from "../../components/Util/Util";
import { SdHeading1 } from "../../components/theming/SdHeading";
import Learn from "./Widgets/Learn";
import Clusters from "./Widgets/Clusters";
import GettingStarted from "./Widgets/GettingStarted";
import Sandboxes from "./Widgets/Sandboxes";
import RecentBlogs from "./Widgets/RecentBlogs";
import styles from "./Overview.module.css";
import Announcements from "./Widgets/Announcements/Announcements";

// Custom hook for calculating optimal column count based on container width
const useOptimalColumns = (containerRef, minWidgetWidth = 450) => {
  const [columns, setColumns] = useState(3);

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateColumns = (width) => {
      // Calculate how many widgets can fit with the minimum width
      const optimalCount = Math.max(1, Math.floor(width / minWidgetWidth));
      return optimalCount;
    };

    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      setColumns(calculateColumns(width));
    });

    // Initial calculation
    setColumns(calculateColumns(containerRef.current.offsetWidth));

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, [containerRef, minWidgetWidth]);

  return columns;
};

const Overview: React.FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const columns = useOptimalColumns(containerRef);

  return (
    <>
      <SdHeading1 small lightBackground>
        Overview
      </SdHeading1>

      <div className={styles.container} ref={containerRef}>
        <div style={{ marginTop: "2rem" }}>
          <Masonry
            columnsCount={columns}
            gutter="2rem"
            className={styles.masonry_layout}
          >
            <Announcements />
            <GettingStarted />
            <Clusters />
            <Sandboxes />
            <Learn />
            <RecentBlogs />
          </Masonry>
        </div>
      </div>

      <Spacer />
    </>
  );
};

export default Overview;
