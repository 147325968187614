import React from "react";
import { Tag } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import type { LocalWorkloadWithStatus } from "../../../@types/sd/sandboxv2";
import LabeledCard from "../../../components/theming/LabeledCard/LabeledCard";
import styles from "./LocalWorkloadCard.module.css";
import Fork from "../Fork";
import SdTheme from "../../../styles/theme";
import ExpandableList from "../../../components/theming/ExpandableList/ExpandableList";

const HOSTNAME_LABEL = "signadot.com/hostname";
const USERNAME_LABEL = "signadot.com/user";

interface LocalWorkloadCardProps {
  localWorkloadWithStatus: LocalWorkloadWithStatus;
  sandboxName: string;
}

const LocalWorkloadCard: React.FC<LocalWorkloadCardProps> = ({
  localWorkloadWithStatus,
  sandboxName,
}) => {
  const navigate = useNavigate();
  const { localWorkload, status } = localWorkloadWithStatus;
  const labels = status?.tunnel.info?.labels;
  const hostname = labels?.[HOSTNAME_LABEL];
  const username = labels?.[USERNAME_LABEL];
  return (
    <LabeledCard
      label="local"
      titleBarContent={
        status ? (
          <Tag
            round
            style={{
              backgroundColor: status.tunnel.health.connected
                ? SdTheme.Status.good
                : SdTheme.Status.bad,
            }}
          >
            {status.tunnel.health.connected ? "Connected" : "Not Connected"}
          </Tag>
        ) : undefined
      }
      buttonInfos={[
        {
          id: "view-detail",
          icon: "list-detail-view",
          label: "View Details",
          onClick: () => {
            navigate(`/sandbox/name/${sandboxName}/workloads`);
          },
        },
      ]}
    >
      <div className={styles.container}>
        <Fork
          parent={`${localWorkload.from.namespace}/${localWorkload.from.name}`}
          child={localWorkload.name}
        />
        {hostname ? (
          <div className={styles.subSection}>
            <div className={styles.title}>Connected To</div>
            {username ? (
              <div className={styles.hostname}>
                {username}@{hostname}
              </div>
            ) : (
              <div className={styles.hostname}>{hostname}</div>
            )}
          </div>
        ) : null}
        <div className={styles.subSection}>
          <div className={styles.title}>Port Mappings</div>
          <ExpandableList
            defaultCount={2}
            items={localWorkload?.mappings?.map((mapping) => (
              <li key={`${localWorkload.name}-map-port-${mapping.port}`}>
                {mapping.port} → {mapping.toLocal}
              </li>
            ))}
          />
        </div>
      </div>
    </LabeledCard>
  );
};

export default LocalWorkloadCard;
