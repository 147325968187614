import type { ComponentType } from "react";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";

import { enableMapSet } from "immer";
import { Chart, registerables } from "chart.js";
import { BrowserTracing } from "@sentry/tracing";
import { usePostHog } from "posthog-js/react";
import Sandboxes from "./pages/Sandboxes";
import Clusters from "./pages/Clusters";
import ApiKeys from "./pages/ApiKeys";

import "./App.css";
import Settings from "./pages/Settings";
import ClusterDetails from "./pages/ClusterDetails";
import Setup from "./pages/Invitation/PostInstall";
import Overview from "./pages/Overview";
import Billing from "./pages/Billing";
import Users from "./components/Users/Users";
import EmailNotVerifiedError from "./components/Auth/EmailNotVerified";
import { AuthContextProvider } from "./contexts/AuthContext";
import ProtectedRouteLayout from "./components/Layout/ProtectedRouteLayout";
import CreateOrgInterstitial from "./pages/CreateOrg";
import ResourceDetails from "./pages/ResourceDetails";
import ResourcePlugins from "./pages/ResourcePlugins";
import Analytics from "./pages/Analytics";
import NewUser from "./pages/NewUser";
import Waiting from "./pages/NewUser/Waiting";
import ManagePlan from "./pages/Billing/ManagePlan";
import SandboxIdRedirect from "./pages/SandboxDetails/SandboxIdRedirect";
import RouteGroups from "./pages/RouteGroups";
import RouteGroupDetails from "./pages/RouteGroups/RouteGroupDetails";
import SetupIntegration from "./pages/Settings/VcsIntegration/SetupIntegration";
import ResourcePluginDetails from "./pages/ResourcePluginDetails/v2";
import CreateSandbox from "./pages/CreateSandbox";
import SandboxDetails from "./pages/SandboxDetails/SandboxDetails";
import NotFound from "./pages/NotFound";
import RunnerGroups from "./pages/Testing/RunnerGroups";
import RunnerGroupDetail from "./pages/Testing/RunnerGroups/RunnerGroupDetail";
import RunnerGroupEditor from "./pages/Testing/RunnerGroups/RunnerGroupEditor";
import Jobs from "./pages/Testing/Jobs";
import JobDetail from "./pages/Testing/Jobs/JobDetail";
import Tests from "./pages/Testing/TestSpecs";
import TestExecutionDetail from "./pages/Testing/TestExecutionDetail";
import TestDetail from "./pages/Testing/TestSpecs/TestDetail";

if (process.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (registerables) {
  Chart.register(...registerables);
}
enableMapSet();

const queryClient = new QueryClient();

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> =
  withAuthenticationRequired(({ component: Component }) => <Component />);

const VITE_SIGNADOT_PREVIEW_SERVER_URL =
  process.env.VITE_SIGNADOT_PREVIEW_SERVER_URL!;

const Signout = () => {
  const posthog = usePostHog();
  posthog?.reset();

  const { logout } = useAuth0();
  logout({
    returnTo: `${VITE_SIGNADOT_PREVIEW_SERVER_URL}/logout?postLogoutRedirectURL=${window.location.origin}`,
  });
  return <div>Signing out...</div>;
};

// Add window.dataLayer type definition
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const sendVirtualPageView = (path: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "virtualPageview",
    page: path,
  });
};

const App = function () {
  const location = useLocation();

  useEffect(() => {
    sendVirtualPageView(location.pathname);
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Sentry.ErrorBoundary
          fallback={
            <p>
              An error has occurred, please try <a href="/">refreshing</a>.
            </p>
          }
        >
          <Routes>
            <Route element={<ProtectedRoute component={ProtectedRouteLayout} />}>
              <Route path="/" element={<Overview />} />
              <Route index element={<Overview />} />
              <Route path="overview" element={<Overview />} />

              <Route path="sandboxes" element={<Sandboxes />} />
              <Route path="sandbox">
                <Route
                  path="name/:sandboxName/*"
                  element={<SandboxDetails />}
                />
                <Route
                  path="id/:sandboxId/resource/:resourceName"
                  element={<ResourceDetails />}
                />

                <Route path="id/:sandboxId" element={<SandboxIdRedirect />} />
              </Route>

              <Route path="resource-plugins">
                <Route index element={<ResourcePlugins />} />
                <Route
                  path="name/:name/*"
                  element={<ResourcePluginDetails />}
                />
              </Route>

              <Route path="workspaces" element={<Navigate to="/sandboxes" />} />
              <Route path="workspace">
                <Route
                  path="id/:sandboxId"
                  element={<Navigate to="/sandbox/id/:sandboxId" />}
                />
                <Route
                  path="id/:sandboxId/deployment/:name"
                  element={
                    <Navigate to="/sandbox/id/:sandboxId/deployment/:name" />
                  }
                />
              </Route>

              <Route path="analytics" element={<Analytics />} />

              <Route path="routegroups">
                <Route index element={<RouteGroups />} />
                <Route
                  path=":routeGroupName/*"
                  element={<RouteGroupDetails />}
                />
              </Route>

              <Route path="settings">
                <Route index element={<Navigate to="apikeys" />} />
                <Route path="apikeys" element={<ApiKeys />} />
                <Route path="global" element={<Settings />} />
                <Route path="clusters" element={<Clusters />} />
              </Route>

              <Route path="cluster">
                <Route path=":clusterName" element={<ClusterDetails />} />
              </Route>

              <Route path="users" element={<Users />} />
              <Route path="billing">
                <Route path="" element={<Billing />} />
                <Route path="plans" element={<ManagePlan />} />
              </Route>

              <Route path="sandboxes/create" element={<CreateSandbox />} />

              <Route path="integrations/setup" element={<SetupIntegration />} />

              <Route path="testing">
                <Route path="job-runner-groups">
                  <Route index element={<RunnerGroups />} />
                  <Route path="editor" element={<RunnerGroupEditor />} />
                  <Route
                    path=":runnerGroupName/*"
                    element={<RunnerGroupDetail />}
                  />
                </Route>
                <Route path="jobs">
                  <Route index element={<Jobs />} />
                  <Route path=":jobName/*" element={<JobDetail />} />
                </Route>
                <Route path="tests">
                  <Route index element={<Tests />} />
                  <Route path=":testName/*" element={<TestDetail />} />
                  <Route
                    path=":testName/executions/:executionName"
                    element={<TestExecutionDetail />}
                  />
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="invitations">
              {/* <Route path="code/:inviteCode" element={<Invitation />} /> */}
              <Route path="post-install" element={<Setup />} />
            </Route>

            <Route path="orgs/setup" element={<CreateOrgInterstitial />} />
            <Route path="users">
              <Route path="new" element={<NewUser />} />
              <Route path="new/waiting" element={<Waiting />} />
            </Route>

            <Route path="signout" element={<Signout />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </AuthContextProvider>
    </QueryClientProvider>
  );
};

export default App;
